import React, { useEffect, useState } from 'react';
import { Box, Center, Container, Flex, Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Image } from '@chakra-ui/react';
import LandingPageBanner from '../assets/images/landing-page-background.png';

const Home = () => {
	const [banner, setBanner] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const router = useRouter();
	useEffect(() => {
		setBanner('');
		if (!sessionStorage.getItem('accessToken')) {
			setError(true);
		}
		setLoading(false);
	}, []);

	const redirectToLogin = () => {
		router.push(`/login`);
	};
	const redirectToLanding = () => {
		router.push('/dashboard');
	};

	return (
		<>
			{loading ? (
				<>
					<Container maxW="container.lg" mt={10}>
						<Flex>
							<Center w="400px">
								<Spinner size="xl" />
							</Center>
							<Box flex="2" display={{ base: 'none', md: 'initial' }}>
								<Image alt="Landing Page Banner" src={banner} fallbackSrc={LandingPageBanner.src} />
							</Box>
						</Flex>
					</Container>
				</>
			) : error ? (
				redirectToLogin()
			) : (
				redirectToLanding()
			)}
		</>
	);
};

export default Home;
